import api from "./api";

export const validateDataPEF = (data) => {
  return api
    .post("/pef_validations", { data })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error("Failed to validate data");
    });
};

export const exportDataPEF = (exportPayload) => {
  return api
    .post("/pef_exports", exportPayload, { responseType: "blob" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response?.data?.error || "Failed to export data");
    });
};
