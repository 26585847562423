import React, { useState } from "react";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";
//import SchemaBasedDataGrid from "../../components/ui/datagrid/SchemaBasedDataGrid";
import FormBuilder from "../../components/ui/datagrid/FormBuilder";
import {
  updateProductData,
  createProductData,
} from "../../services/productService";
import { Button, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// Define status priority: Refused > To Complete > In Progress > Submitted > Archived
const STATUS_PRIORITY = [
  "refused",
  "to_complete",
  "in_progress",
  "submitted",
  "archived",
];

const ProductDataList = ({ products, productDataMap, refreshProducts }) => {
  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentProductData, setCurrentProductData] = useState(null);

  // New State to Track Form Changes
  const [isFormDirty, setIsFormDirty] = useState(false);

  // Define columns for DataGrid
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) => a.brand_name.localeCompare(b.brand_name),
    },
    {
      title: "Data Type",
      dataIndex: "data_type",
      key: "data_type",
      sorter: (a, b) => a.data_type.localeCompare(b.data_type),
      render: (text) => text || "N/A",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      /*defaultSortOrder: 'ascend', // Sorts based on the defined priority
      sorter: (a, b) => {
        const statusA = STATUS_PRIORITY.indexOf(a.status.toLowerCase());
        const statusB = STATUS_PRIORITY.indexOf(b.status.toLowerCase());
        return statusA - statusB;
      },*/
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => <StatusBadge status={text} />,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
    },
    /*{
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          onClick={() =>
            handleFillData(record.product_id, record.product_data_id)
          }
          className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          aria-label={`Fill data for ${record.name}`}
        >
          Fill Data
        </button>
      ),
    },*/
    {
      dataIndex: "actions",
      title: "Actions",
      key: "actions",

      render: (text, record) => {
        const status = record.status.toLowerCase();
        console.log("Status:", status);
        let actionLabel = "";
        let isDisabled = false;

        switch (status) {
          case "to_complete":
            actionLabel = "Fill Data";
            break;
          case "submitted":
            actionLabel = "View Details";
            break;
          case "refused":
            actionLabel = "Correct Data";
            break;
          case "accepted":
          case "forced_submitted":
            actionLabel = "View Details";
            break;
          case "in_progress":
            actionLabel = "Continue Editing";
            break;
          default:
            actionLabel = "View Details";
            isDisabled = true;
        }
        return (
          <button
            onClick={() =>
              handleFillData(record.product_id, record.product_data_id)
            }
            disabled={isDisabled}
            className={`px-3 py-1 rounded ${
              isDisabled
                ? "bg-gray-300"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {actionLabel}
          </button>
        );
      },
    },
  ];

  // Prepare rows by combining product and product_data details
  const rows = products.flatMap((product) => {
    const productDataArray = productDataMap[product.id];

    if (Array.isArray(productDataArray) && productDataArray.length > 0) {
      return productDataArray.map((productData) => ({
        id: productData.id,
        product_id: product.id,
        product_data_id: productData.id,
        name: product.attributes.name,
        reference: product.attributes.reference_supplier,
        brand_name: product.attributes.brand_name,
        data_type: productData.attributes.data_type,
        status: productData.attributes.status,
        comments: productData.attributes.comments,
        updated_at: new Date(
          productData.attributes.updated_at
        ).toLocaleString(),
      }));
    } else {
      // Display a row with 'N/A' for data_type and status
      return [
        {
          id: `no-data-${product.id}`,
          product_id: product.id,
          product_data_id: null,
          name: product.attributes.name,
          reference: product.attributes.reference_supplier,
          brand_name: product.attributes.brand_name,
          data_type: "N/A",
          status: "N/A",
          comments: "",
        },
      ];
    }
  });

  const handleFillData = (productId, productDataId) => {
    let existingData = null;
    if (productDataId) {
      const productDataArray = productDataMap[productId];
      existingData = productDataArray.find((data) => data.id === productDataId);
    }
    const product = products.find((p) => p.id === productId);
    setCurrentProduct(product);
    setCurrentProductId(productId);
    setCurrentProductData(existingData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentProductId(null);
    setCurrentProductData(null);
    setCurrentProduct(null);
  };

  // Add forced submit action to handleFormSubmit
  const handleFormSubmit = async (formData, forced_submit = false) => {
    try {
      if (currentProductData) {
        const actionType = forced_submit ? "force_submit" : "submit";
        await updateProductData(
          currentProductId,
          currentProductData.id,
          formData,
          actionType
        );
        message.success("Form submitted successfully");
      } /*else {
        // Create new product data
        await createProductData(currentProductId, formData);
        message.success("Product data created successfully.");
      }*/
      // Refresh data in SupplierDashboard
      await refreshProducts();
      closeModal();
      setIsFormDirty(false); // Optionally reset dirty flag
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Failed to submit product data.");
    }
  };

  const handleDraft = async (formData) => {
    try {
      if (currentProductData) {
        // Update existing product data
        await updateProductData(
          currentProductId,
          currentProductData.id,
          formData
        );
        message.success("Draft saved successfully");
        await refreshProducts();
        closeModal();
        setIsFormDirty(false); // Optionally reset dirty flag
      }
    } catch (error) {
      console.error("Error saving draft:", error);
      message.error("Failed to save product data draft.");
    }
  };

  return (
    <div className="">
      {/* Enhanced DataGrid */}
      {rows.length > 0 ? (
        <DataGrid data={rows} columns={columns} />
      ) : (
        <div className="p-6 text-center text-gray-500">
          <p>No products found.</p>
        </div>
      )}

      {/* Modal for Filling Data */}
      <Modal
        visible={isModalOpen}
        footer={null}
        width="80%"
        maskClosable={!isFormDirty} // Prevent closing by clicking mask if dirty
        keyboard={!isFormDirty}
        onCancel={() => {
          if (isFormDirty) {
            Modal.confirm({
              title: "Unsaved Changes",
              icon: <ExclamationCircleOutlined />,
              content:
                "It looks like you have unsaved changes. Are you sure you want to leave this page? Any unsaved progress will be lost!",
              okText: "Stay",
              cancelText: "Leave",
              onOk: () => {},
              onCancel: () => {
                closeModal();
                setIsFormDirty(false); // Optionally reset dirty flag
              },
            });
          } else {
            closeModal();
          }
        }}
        destroyOnClose={true}
        style={{ top: 20 }}
        bodyStyle={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">
            {currentProductData
              ? "Edit Product: " + currentProduct.attributes.name
              : "Add Product Data"}
          </h3>
        </div>
        <FormBuilder
          schema={
            currentProductData
              ? currentProductData.attributes.form_template?.schema
              : null
          }
          uiSchema={
            currentProductData
              ? currentProductData.attributes.form_template?.ui_schema
              : null
          }
          initialData={
            currentProductData ? currentProductData.attributes.form_data : {}
          }
          onSubmit={handleFormSubmit}
          onDraft={handleDraft}
          onChange={() => setIsFormDirty(true)}
          topLevelInfo={{
            data: currentProduct,
          }}
          comments={
            currentProductData?.attributes.status === "refused"
              ? currentProductData?.attributes.comment
              : null
          }
        />
      </Modal>
    </div>
  );
};

export default ProductDataList;
