// AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const [user, setUser] = useState(null);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  const getDefaultRoute = (userData) => {
    if (!userData) return '/login';
    if (userData.force_password_change) return '/login';
    const role = userData.role.split('_')[0];
    return `/${role}`;
  };

  useEffect(() => {
    const initializeAuth = async () => {
      const storedToken = localStorage.getItem("authToken");
      const storedUser = localStorage.getItem("user");

      if (storedToken && !isTokenExpired(storedToken)) {
        try {
          const parsedUser = JSON.parse(storedUser);
          setAuthToken(storedToken);
          setUser(parsedUser);
          setForcePasswordChange(parsedUser.force_password_change || false);

          // Only redirect if we're at login or root path AND no forced password change
          if ((location.pathname === '/' || location.pathname === '/login') && !parsedUser.force_password_change) {
            navigate(getDefaultRoute(parsedUser));
          }
        } catch (error) {
          clearAuthData();
        }
      } else if (storedToken) {
        clearAuthData();
      }

      setIsLoading(false);
    };

    initializeAuth();
  }, [navigate, location.pathname]);

  const clearAuthData = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    setAuthToken(null);
    setUser(null);
    setForcePasswordChange(false);
    navigate("/login");
  };

  const login = async (token, userData, shouldNavigate = true) => {
    if (!userData || !token) {
      console.error("User data or token is undefined");
      return;
    }

    if (isTokenExpired(token)) {
      console.error("Token is expired");
      clearAuthData();
      return;
    }

    localStorage.setItem("authToken", token);
    localStorage.setItem("user", JSON.stringify(userData));
    setAuthToken(token);
    setUser(userData);
    setForcePasswordChange(userData.force_password_change || false);

    if (shouldNavigate) {
      navigate(getDefaultRoute(userData));
    }
  };

  const updateUserData = (userData) => {
    if (!userData) {
      console.error("User data is undefined");
      return;
    }

    const currentToken = localStorage.getItem("authToken");
    if (!currentToken || isTokenExpired(currentToken)) {
      console.error("No valid auth token found");
      clearAuthData();
      return;
    }

    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
    setForcePasswordChange(userData.force_password_change || false);
  };

  const logout = async () => {
    clearAuthData();
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        user,
        login,
        logout,
        isLoading,
        updateUserData,
        forcePasswordChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};