// FormActionButtons.js
import React from "react";
import {
  DraftingCompass,
  Save,
  XCircle,
  CheckCircle,
  Check,
  SendHorizontal,
} from "lucide-react";

const BUTTON_CONFIGS = {
    SUPPLIER: {
        draft: {
            icon: DraftingCompass,
            label: "Save as Draft",
            className: "bg-gray-600 hover:bg-gray-700",
        },
        submit: {
            icon: Save,
            label: "Verify & Submit",
            className: "bg-blue-600 hover:bg-blue-700",
        },
    },
    ADMIN: {
        draft: {
            icon: DraftingCompass,
            label: "Save as Draft",
            className: "bg-gray-600 hover:bg-gray-700",
        },
        verify: {
            icon: Check,
            label: "Verify",
            className: "bg-yellow-600 hover:bg-yellow-700",
        },
        submit: {
            icon: SendHorizontal,
            label: "Submit",
            className: "bg-blue-600 hover:bg-blue-700",
        },
        reject: {
            icon: XCircle,
            label: "Reject",
            className: "bg-red-600 hover:bg-red-700",
        },
        approve: {
            icon: CheckCircle,
            label: "Approve",
            className: "bg-green-600 hover:bg-green-700",
        },
    },
};

const ActionButton = ({ config, onClick, disabled }) => {
  const Icon = config.icon;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center justify-center gap-2 text-white py-2 px-6 rounded-md transition-colors disabled:opacity-50 ${config.className}`}
    >
      <Icon className="w-5 h-5" />
      {config.label}
    </button>
  );
};

const FormActionButtons = ({
  role = "SUPPLIER",
  isSubmitting,
  onDraft,
  onVerify,
  onSubmit,
  onReject,
  onApprove,
  mode = "edit",
}) => {
  if (mode === "preview") return null;

  const configs = BUTTON_CONFIGS[role];
  if (!configs) return null;

  const handleAction = (actionType, ...args) => {
    if (isSubmitting) return;

    switch (actionType) {
      case "draft":
        return onDraft?.(...args);
      case "verify":
        return onVerify?.(...args);
      case "submit":
        return onSubmit?.(...args);
      case "reject":
        return onReject?.(...args);
      case "approve":
        return onApprove?.(...args);
      default:
        return;
    }
  };

  return (
    <div className="sticky bottom-0 bg-gray-50 p-4">
      <div className="flex justify-end gap-4">
        {/* Draft button is available for all roles */}
        <ActionButton
          config={configs.draft}
          onClick={() => handleAction("draft", true)}
          disabled={isSubmitting}
        />

        {role === "SUPPLIER" ? (
          // Supplier only gets combined verify & submit button
          <ActionButton
            config={configs.submit}
            onClick={() => handleAction("submit", false)}
            disabled={isSubmitting}
          />
        ) : (
          // Admin gets separate verify and submit buttons
          <>
            <ActionButton
              config={configs.verify}
              onClick={() => handleAction("verify")}
              disabled={isSubmitting}
            />
            <ActionButton
              config={configs.submit}
              onClick={() => handleAction("submit")}
              disabled={isSubmitting}
            />
          </>
        )}

        {/* Admin-specific buttons */}
        {role === "ADMIN" && (
          <>
            {onReject && (
              <ActionButton
                config={configs.reject}
                onClick={() => handleAction("reject")}
                disabled={isSubmitting}
              />
            )}
            {onApprove && (
              <ActionButton
                config={configs.approve}
                onClick={() => handleAction("approve")}
                disabled={isSubmitting}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FormActionButtons;
