import {
  FaBoxOpen,
  FaChartBar,
  FaChartPie,
  FaFileAlt,
  FaSpinner,
  FaCheckCircle,
  FaPaperPlane,
} from "react-icons/fa";

export function getProductKpis({
  products,
  includedData,
}) {
  // derive counts
  const total = products.length;
  const published = products.filter(
    (p) => p.attributes.status === "active"
  ).length;
  const draft = products.filter((p) => p.attributes.status === "draft").length;
  const archived = products.filter(
    (p) => p.attributes.status === "archived"
  ).length;

  const productDatumStatuses = includedData
    .filter((item) => item.type === "product_datum")
    .map((i) => i.attributes.status);

  const totalData = productDatumStatuses.length;
  const inProgress = productDatumStatuses.filter(
    (s) => s === "in_progress"
  ).length;
  const completed = productDatumStatuses.filter((s) => s === "accepted").length;
  const submitted = productDatumStatuses.filter((s) =>
    ["forced_submitted", "submitted", "accepted"].includes(s)
  ).length;

  return [
    {
      title: "Total Products",
      value: total,
      icon: <FaBoxOpen className="h-6 w-6 text-indigo-400" />,
      color: "border-l-4 border-indigo-500",
    },
    {
      title: "Active Products",
      value: published,
      icon: <FaChartBar className="h-6 w-6 text-green-400" />,
      color: "border-l-4 border-green-500",
    },
    {
      title: "Draft Products",
      value: draft,
      icon: <FaChartPie className="h-6 w-6 text-yellow-400" />,
      color: "border-l-4 border-yellow-500",
    },
    {
      title: "Archived Products",
      value: archived,
      icon: <FaBoxOpen className="h-6 w-6 text-grey-400" />,
      color: "border-l-4 border-grey-500",
    },
    {
      title: "Product Data Requests",
      value: totalData,
      icon: <FaFileAlt className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-gray-500",
    },
    {
      title: "Completed Data",
      value: completed,
      icon: <FaCheckCircle className="h-6 w-6 text-green-400" />,
      color: "border-l-4 border-green-500",
    },
    {
      title: "In Progress Data",
      value: inProgress,
      icon: <FaSpinner className="h-6 w-6 text-blue-400" />,
      color: "border-l-4 border-blue-500",
    },
    {
      title: "Submitted Data (to be reviewed)",
      value: submitted,
      icon: <FaPaperPlane className="h-6 w-6 text-purple-400" />,
      color: "border-l-4 border-purple-500",
    },
  ];
}
