// src/components/ui/modals/ImportEntityModal.js
import React, { useState } from "react";
import { Modal, Upload, Button, Progress, message, Alert, List } from "antd";
import { UploadOutlined, CopyOutlined } from "@ant-design/icons";

/**
 * A generic modal for importing entities (e.g., Products, Suppliers) via CSV.
 *
 * Props:
 * - visible: boolean indicating if the modal is open
 * - onClose: function to call when modal is closed
 * - onImportSuccess: function to call when the import completes successfully
 * - importServiceFunction: function(file, callback) that starts the import process and calls callback(status)
 * - entityName: string (e.g., "Products", "Suppliers") for labels
 */
const ImportEntityModal = ({
  visible,
  onClose,
  onImportSuccess,
  importServiceFunction,
  entityName = "Entities",
  headers = [],
}) => {
  const [fileList, setFileList] = useState([]);
  const [importing, setImporting] = useState(false);
  const [importStatus, setImportStatus] = useState(null);
  const [error, setError] = useState(null);

  const beforeUpload = (file) => {
    const isCSV = file.type === "text/csv";
    if (!isCSV) {
      message.error("You can only upload CSV files!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("File must be smaller than 5MB!");
    }
    return isCSV && isLt5M;
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error("Please select a CSV file to upload.");
      return;
    }

    const file = fileList[0].originFileObj;
    setImporting(true);
    setImportStatus(null);
    setError(null);

    try {
      await importServiceFunction(file, (status) => {
        if (status.error) {
          setError(status.error);
          setImporting(false);
        } else {
          setImportStatus(status);

          if (status.status === "completed") {
            setImporting(false);

            if (status.errors > 0) {
              message.warning(
                `Import completed with ${status.errors} error(s). Please check the errors below.`
              );
            } else {
              message.success("Import completed successfully!");
              onImportSuccess?.();
              // Close the modal after 2 seconds
              setTimeout(() => {
                onClose();
                setFileList([]);
                setImportStatus(null);
                setError(null);
              }, 2000);
            }
          } else if (status.status === "failed") {
            setImporting(false);
            message.error("Import failed. Please check the errors below.");
          }
        }
      });
    } catch (err) {
      setError(err.message);
      setImporting(false);
      message.error("Failed to start import.");
    }
  };

  const handleCancel = () => {
    if (!importing) {
      onClose();
      setFileList([]);
      setImportStatus(null);
      setError(null);
    }
  };

  const getProgressStatus = () => {
    if (!importStatus) return "normal";

    if (importStatus.errors > 0) {
      return "exception"; // red progress bar
    } else if (importStatus.status === "completed") {
      return "success"; // green progress bar
    } else if (importStatus.status === "failed") {
      return "exception";
    } else {
      return "active"; // blue progress bar (active)
    }
  };

  return (
    <Modal
      open={visible}
      title={`Import ${entityName} via CSV`}
      onCancel={handleCancel}
      width={800}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={importing}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleUpload}
          disabled={importing || fileList.length === 0}
          loading={importing}
        >
          {importing ? `Importing ${entityName}...` : `Start Import`}
        </Button>,
      ]}
    >
      {headers && headers.length > 0 && (
        <div className="mt-4 pb-8">
          <h3 className="font-semibold mb-2">Required CSV Format:</h3>
          <pre className="bg-gray-100 p-4 rounded text-sm overflow-auto">
            {headers.join(";")}
          </pre>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(headers.join(";"));
              message.success("Headers copied!");
            }}
            icon={<CopyOutlined />}
            className="mt-2 float-right"
            type="dashed"
          >
            Copy Headers
          </Button>
        </div>
      )}

      <div className="space-y-4 mt-4">
        <Upload
          accept=".csv"
          beforeUpload={beforeUpload}
          onRemove={() => setFileList([])}
          fileList={fileList}
          onChange={({ fileList }) => setFileList(fileList.slice(-1))}
          multiple={false}
          maxCount={1}
          disabled={importing}
        >
          <Button icon={<UploadOutlined />} disabled={importing} type="primary">
            Select CSV File
          </Button>
        </Upload>

        {importStatus && (
          <div className="space-y-4">
            <div>
              <div className="flex justify-between mb-2">
                <span>
                  Progress: {importStatus.processed} of {importStatus.total}{" "}
                  {entityName.toLowerCase()}
                </span>
                <span>
                  Success: {importStatus.success} | Errors:{" "}
                  {importStatus.errors}
                </span>
              </div>
              <Progress
                percent={Math.round(
                  (importStatus.processed / importStatus.total) * 100
                )}
                status={getProgressStatus()}
              />
            </div>

            {importStatus.recentErrors?.length > 0 && (
              <Alert
                type="error"
                message="Errors on import"
                description={
                  <List
                    size="small"
                    dataSource={importStatus.recentErrors}
                    renderItem={({ row, errors }) => (
                      <List.Item>
                        Row {row}: {errors.join(", ")}
                      </List.Item>
                    )}
                  />
                }
                style={{ maxHeight: "200px", overflowY: "auto" }}
              />
            )}
          </div>
        )}

        {error && (
          <Alert type="error" message="Import Error" description={error} />
        )}
      </div>
    </Modal>
  );
};

export default ImportEntityModal;
