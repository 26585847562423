// src/components/layout/Header.js
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Layout, Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';

const { Header: AntHeader } = Layout;

const logo = require('../../assets/images/cc/logo-crystalcheck.png');

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  const handleSettings = () => {
    const role = user.role;
    navigate(`/${role}/settings`);
  };

  const menu = (
    <Menu>
      <Menu.Item key="settings" icon={<SettingOutlined />} onClick={handleSettings}>
        Settings
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader className="bg-white px-6 py-0 flex items-center justify-between shadow">
      {/* Left Section: Logo */}
      <div className="flex-1 flex items-center">
        <Link to={`/${user.role}`}>
          <img src={logo} alt="Logo" className="h-14 w-auto" />
        </Link>
      </div>

      {/* Middle Section: Application Title */}
      <div className="flex-1 flex justify-center">
        <div className="text-xl font-bold">
          <Link to={`/${user.role}`}>
            <h1 className="text-3xl font-bold">
              Crystal
              <span className="bg-blue-600 text-white px-2">Collect</span>
            </h1>
          </Link>
        </div>
      </div>

      {/* Right Section: Profile Icon */}
      <div className="flex-1 flex justify-end items-center">
        {user && (
          <Dropdown overlay={menu} placement="bottomRight">
            <Avatar
              size="large"
              icon={<UserOutlined />}
              style={{ cursor: 'pointer', backgroundColor: '#87d068' }}
            />
          </Dropdown>
        )}
      </div>
    </AntHeader>
  );
};

export default Header;
