import React, { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

const PrivateRoute = ({ children, roles }) => {
  const { user, isLoading, authToken, logout } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    const storedUser = localStorage.getItem("user");

    if (!storedToken || !storedUser) {
      logout();
    }
  }, [logout]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user || !authToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (roles && !roles.includes(user.role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default PrivateRoute;