import React, { useState, useEffect } from "react";
import { Spin, Modal } from "antd";
import { FaHeadset } from "react-icons/fa";

const SupportForm = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const formWidth = 600;
  const formHeight = 420;

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === "https://desk.zoho.eu") {
        try {
          if (event.data.includes("reloadZsIframe")) {
            // Wait for Zoho's success modal animation
            setTimeout(() => {
              onClose();
            }, 1000);
          }
        } catch (e) {
          console.error("Error handling message:", e);
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [onClose]);

  const handleOnLoad = () => setIsLoading(false);
  const handleOnError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={null}
      width={formWidth + 60}
      centered
      bodyStyle={{
        height: formHeight,
        padding: 0,
        overflow: "hidden",
      }}
      style={{
        maxWidth: "90vw",
        maxHeight: "90vh",
      }}
      title={
        <div className="flex items-center space-x-2 pb-2">
          <FaHeadset className="text-blue-500 text-2xl" />
          <h2 className="text-gray-800 font-semibold text-xl">Support</h2>
        </div>
      }
    >
      <div className="w-full h-full relative">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
            <Spin size="large" />
          </div>
        )}
        {hasError ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-center">
              <p className="text-gray-500 mb-4">
                Failed to load the support form. Please try again later.
              </p>
              <button
                onClick={() => window.location.reload()}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              >
                Try Again
              </button>
            </div>
          </div>
        ) : (
          <iframe
            id="zsfeedbackFrame"
            name="zsfeedbackFrame"
            title="Support form"
            onLoad={handleOnLoad}
            onError={handleOnError}
            src="https://desk.zoho.eu/support/fbw?formType=AdvancedWebForm&fbwId=edbsn254c574e52df2227cf686b4ce5158ee33879bce20233eb7aadc3564585beabeb&xnQsjsdp=edbsn31ba75ddc11d6020b55afa7dc8684b72&mode=showNewWidget&displayType=iframe"
            style={{
              width: formWidth,
              height: formHeight,
              border: "none",
              display: "block",
              margin: "0 auto",
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default SupportForm;