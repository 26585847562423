import StatusBadge from "../../components/ui/badges/StatusBadge";
import { Button } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";

export function getProductColumns({
  brands,
  suppliers,
  products,
  productDataMap,
  handleEdit,
  handleDelete,
  handleViewDetails,
}) {
  return [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) => a.brand_name.localeCompare(b.brand_name),
      filters: brands.map((brand) => ({
        text: brand.attributes.name,
        value: brand.attributes.name,
      })),
      onFilter: (value, record) => record.brand_name === value,
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
      filters: suppliers.map((supplier) => ({
        text: supplier.attributes.name,
        value: supplier.attributes.name,
      })),
      onFilter: (value, record) => record.supplier_name === value,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => <StatusBadge status={status} />,
      filters: Array.from(
        new Set(products.map((product) => product.attributes.status))
      ).map((status) => ({
        text: status.charAt(0).toUpperCase() + status.slice(1),
        value: status.charAt(0).toUpperCase() + status.slice(1), // Capitalize value
      })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Product Data Status",
      dataIndex: "productDataStatuses",
      key: "productDataStatuses",
      filters: Array.from(
        new Set(
          products.flatMap((product) =>
            productDataMap[product.id]?.map((datum) => datum.status)
          ) || []
        )
      )?.map((status) => ({
        text:
          status?.replace(/_/g, " ").charAt(0).toUpperCase() +
          status?.replace(/_/g, " ").slice(1),
        value: status, // Use exact status value from data
      })),
      onFilter: (value, record) => {
        return record.productDataStatuses.includes(value);
      },
      sorter: (a, b) => {
        const statusA = a.productDataStatuses[0] || "";
        const statusB = b.productDataStatuses[0] || "";
        return statusA.localeCompare(statusB);
      },
      render: (statuses) => (
        <>
          {statuses.map((status, index) => (
            <StatusBadge key={index} status={status} />
          ))}
        </>
      ),
    },
    {
      dataIndex: "actions",
      title: "Actions",
      render: (_, record) => (
        <div className="flex space-x-2">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(record.originalProduct);
            }}
            type="primary"
            size="small"
            icon={<FaEdit />}
          />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(record.originalProduct.id);
            }}
            danger
            type="primary"
            size="small"
            icon={<FaTrash />}
          />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetails(record.originalProduct.id);
            }}
            size="small"
          >
            View
          </Button>
        </div>
      ),
    },
  ];
}
