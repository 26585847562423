import React, { useEffect, useState } from "react";
import { getSupplier } from "../../services/supplierService";
import { useParams, useNavigate } from "react-router-dom";
import InfoCard from "../../components/ui/cards/InfoCard";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import { Button } from "antd";

const SupplierDetail = () => {
  const { id } = useParams();
  const [supplier, setSupplier] = useState(null);
  const navigate = useNavigate();
  const [UsersData, setUsersData] = useState([]);
  const [ProductsData, setProductsData] = useState([]);
  const [BrandsData, setBrandsData] = useState([]);

  useEffect(() => {
    fetchSupplierDetails();
  }, [id]);

  const fetchSupplierDetails = () => {
    getSupplier(id)
      .then((response) => {
        setSupplier(response.data);

        // Reset the arrays before setting new data
        setUsersData([]);
        setProductsData([]);
        setBrandsData([]);

        // Loop through included data to populate related entities
        if (response.data.included) {
          response.data.included.forEach((element) => {
            if (element.type === "user") {
              setUsersData((prev) => [...prev, element]);
            }
            if (element.type === "product") {
              setProductsData((prev) => [...prev, element]);
            }
          });
        }

        // Set brands data
        setBrandsData(response.data.data.attributes.brands);
      })
      .catch((error) => {
        console.error("Error fetching supplier details", error);
      });
  };

  if (!supplier) {
    return <div className="p-6">Loading...</div>;
  }

  // Define fields for InfoCard
  const supplierFields = [
    { name: "address", label: "Address" },
    { name: "country", label: "Country" },
    {
      name: "tva_number",
      label: "TVA Number",
    },
    {
      name: "created_at",
      label: "Created At",
      render: (value) => new Date(value).toLocaleDateString(),
    },
    {
      name: "updated_at",
      label: "Updated At",
      render: (value) => new Date(value).toLocaleDateString(),
    },
  ];

  // Prepare brands data for DataGrid
  const brands = BrandsData.map((brand) => ({
    id: brand.id,
    name: brand.name,
    country: brand.country,
  }));

  // Define columns for brands
  const columnsBrand = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 200,
    },
  ];

  const handleBrandClick = (brand) => {
    navigate(`/admin/brands/${brand.id}`);
  };

  const products = ProductsData.map((product) => {
    // Extract the brand ID from the product's relationships
    const brandId = product.relationships.brand.data.id;

    // Find the corresponding brand in BrandsData
    const brand = BrandsData.find((brand) => brand.id === brandId);

    return {
      id: product.id,
      name: product.attributes.name,
      status: product.attributes.status,
      reference_brand: product.attributes.reference_brand,
      reference_supplier: product.attributes.reference_supplier,
      brand: brand ? brand.name : "N/A", // Use the brand name or default to "N/A"
    };
  });

  // Define columns for products
  const columnsProduct = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (text) => <StatusBadge status={text} />,
    },
    {
      title: "Reference Brand",
      dataIndex: "reference_brand",
      key: "reference_brand",
      width: 200,
    },
    {
      title: "Reference Supplier",
      dataIndex: "reference_supplier",
      key: "reference_supplier",
      width: 200,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 200,
    },
  ];

  const handleProductClick = (product) => {
    navigate(`/admin/manage-products/${product.id}`);
  };

  // Prepare users data for DataGrid
  const users = UsersData.map((user) => ({
    id: user.id,
    name: user.attributes.name,
    email: user.attributes.email,
    role: user.attributes.role,
    status: user.attributes.invitation_status,
  }));

  // Define columns for users
  const columnsUser = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (text) => <StatusBadge status={text} />,
    },
  ];

  return (
    <div className="p-6">
      <Button onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>

      {/* Supplier Information */}
      <InfoCard
        title={supplier.data.attributes.name}
        fields={supplierFields}
        data={supplier.data.attributes}
      />

      {/* Brands Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Brands</h3>
        <DataGrid
          columns={columnsBrand}
          data={brands}
          onRowClick={handleBrandClick}
        />
      </div>

      {/* Users Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Users</h3>
        <DataGrid columns={columnsUser} data={users} />
      </div>

      {/* Products Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Products</h3>
        <DataGrid
          columns={columnsProduct}
          data={products}
          onRowClick={handleProductClick}
        />
      </div>
    </div>
  );
};

export default SupplierDetail;
