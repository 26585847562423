import React, { useEffect, useState, useMemo } from "react";
import { Modal, Form, Select, Checkbox, Button, Spin, Alert } from "antd";
import { FaDownload, FaCheckCircle } from "react-icons/fa";

// We'll assume you have { Option } = Select
const { Option } = Select;

const ExportEntityModal = ({
  visible,
  onClose,
  brands,
  suppliers,
  products,
  productDataMap,
  // Instead of a global dataTypes array, we derive them from available products
  // dataTypes, 
  onExport, // returns a Promise that resolves to { blob, filename }
}) => {
  const [form] = Form.useForm();

  // Step: "form" | "success"
  const [step, setStep] = useState("form");

  // Selections
  const [allBrands, setAllBrands] = useState(false);
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);

  const [allSuppliers, setAllSuppliers] = useState(false);
  const [selectedSupplierIds, setSelectedSupplierIds] = useState([]);

  const [allProducts, setAllProducts] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const [allDataTypes, setAllDataTypes] = useState(false);
  const [selectedDataTypes, setSelectedDataTypes] = useState([]);

  // Loading and errors
  const [isExporting, setIsExporting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // On success, store the blob and filename, so user can explicitly click "Download"
  const [exportBlob, setExportBlob] = useState(null);
  const [exportFilename, setExportFilename] = useState("export.csv");

  // Reset state when the modal opens
  useEffect(() => {
    if (visible) {
      resetFormState();
    }
  }, [visible]);

  // Toggle brand selection
  useEffect(() => {
    if (allBrands) {
      setSelectedBrandIds(brands.map((b) => b.id));
    } else {
      setSelectedBrandIds([]);
    }
  }, [allBrands, brands]);

  // Toggle supplier selection
  useEffect(() => {
    if (allSuppliers) {
      setSelectedSupplierIds(suppliers.map((s) => s.id));
    } else {
      setSelectedSupplierIds([]);
    }
  }, [allSuppliers, suppliers]);

  // Toggle product selection
  useEffect(() => {
    if (allProducts) {
      setSelectedProductIds(products.map((p) => p.id));
    } else {
      setSelectedProductIds([]);
    }
  }, [allProducts, products]);

  // We’ll figure out "availableProducts" based on brand/supplier filtering
  // and only show data types from those "availableProducts."
  const allowedStatuses = ["submitted", "forced_submitted", "accepted"];
  const allPossibleProducts = products.filter((product) => {
    const productDatums = productDataMap[product.id] || [];
    return productDatums.some((datum) => allowedStatuses.includes(datum.status));
  });

  const availableSuppliers = allBrands
    ? suppliers
    : suppliers.filter((supplier) => {
        const supplierBrandIds = supplier.attributes.brands.map((b) => b.id);
        return supplierBrandIds.some((brandId) =>
          selectedBrandIds.includes(brandId)
        );
      });

  const availableProducts = allPossibleProducts.filter((p) => {
    const brandId = p.relationships.brand.data.id;
    if (!allBrands && selectedBrandIds.length && !selectedBrandIds.includes(brandId)) {
      return false;
    }
    const supplierId = p.relationships.supplier.data.id;
    if (!allSuppliers && selectedSupplierIds.length && !selectedSupplierIds.includes(supplierId)) {
      return false;
    }
    return true;
  });

  // If "Select All Products," pick them all
  const finalSelectedProducts = allProducts
    ? availableProducts.map((p) => p.id)
    : selectedProductIds.filter((id) =>
        availableProducts.some((p) => p.id === id)
      );

  // Now derive the data types from just the "availableProducts"
  const derivedDataTypes = useMemo(() => {
    const setOfTypes = new Set();
    availableProducts.forEach((prod) => {
      const pdArray = productDataMap[prod.id] || [];
      pdArray.forEach((datum) => {
        if (allowedStatuses.includes(datum.status)) {
          setOfTypes.add(datum.data_type);
        }
      });
    });
    return Array.from(setOfTypes);
  }, [availableProducts, productDataMap, allowedStatuses]);

  // Handle Export
  const handleExportClick = () => {
    setIsExporting(true);
    setErrorMessage("");

    const filters = {
      brandIds: selectedBrandIds,
      supplierIds: selectedSupplierIds,
      productIds: finalSelectedProducts,
      isAllBrands: allBrands,
      isAllSuppliers: allSuppliers,
      isAllProducts: allProducts,

      dataTypes: selectedDataTypes,
      isAllDataTypes: allDataTypes,
    };

    onExport(filters)
      .then(({ blob, filename }) => {
        // We got the blob and a suggested filename
        setExportBlob(blob);
        setExportFilename(filename);
        setStep("success"); // show success UI
      })
      .catch((err) => {
        setErrorMessage(`Export failed: ${err.message || err}`);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  // If we’re on "success," the user can click "Download" or "Close."
  const handleDownloadClick = () => {
    if (!exportBlob) return;
    const url = URL.createObjectURL(exportBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = exportFilename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const resetFormState = () => {
    setStep("form");
    setErrorMessage("");
    setIsExporting(false);

    setAllBrands(false);
    setSelectedBrandIds([]);

    setAllSuppliers(false);
    setSelectedSupplierIds([]);

    setAllProducts(false);
    setSelectedProductIds([]);

    setAllDataTypes(false);
    setSelectedDataTypes([]);

    setExportBlob(null);
    setExportFilename("export.csv");
  };

  const renderFormStep = () => (
    <>
      {errorMessage && (
        <Alert
          type="error"
          message={errorMessage}
          closable
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}

      {/* BRAND SELECTION */}
      <Form.Item label="Brands">
        <Checkbox
          checked={allBrands}
          onChange={(e) => setAllBrands(e.target.checked)}
        >
          Select All Brands
        </Checkbox>
        {!allBrands && (
          <Select
            mode="multiple"
            value={selectedBrandIds}
            onChange={(vals) => setSelectedBrandIds(vals)}
            style={{ width: "100%", marginTop: 8 }}
            placeholder="Select brand(s)"
          >
            {brands.map((brand) => (
              <Option key={brand.id} value={brand.id}>
                {brand.attributes.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>

      {/* SUPPLIER SELECTION */}
      <Form.Item label="Suppliers">
        <Checkbox
          checked={allSuppliers}
          onChange={(e) => setAllSuppliers(e.target.checked)}
        >
          Select All Suppliers
        </Checkbox>
        {!allSuppliers && (
          <Select
            mode="multiple"
            value={selectedSupplierIds}
            onChange={(vals) => setSelectedSupplierIds(vals)}
            style={{ width: "100%", marginTop: 8 }}
            placeholder="Select supplier(s)"
          >
            {availableSuppliers.map((s) => (
              <Option key={s.id} value={s.id}>
                {s.attributes.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>

      {/* PRODUCT SELECTION */}
      <Form.Item label="Products">
        <Checkbox
          checked={allProducts}
          onChange={(e) => setAllProducts(e.target.checked)}
        >
          Select All Products
        </Checkbox>
        {!allProducts && (
          <Select
            mode="multiple"
            value={selectedProductIds}
            onChange={(vals) => setSelectedProductIds(vals)}
            style={{ width: "100%", marginTop: 8 }}
            placeholder="Select product(s)"
          >
            {availableProducts.map((p) => (
              <Option key={p.id} value={p.id}>
                {p.attributes.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>

      {/* DATA TYPES (DERIVED) */}
      <Form.Item label="Data Types">
        <Checkbox
          checked={allDataTypes}
          onChange={(e) => setAllDataTypes(e.target.checked)}
        >
          Select All Data Types
        </Checkbox>
        {!allDataTypes && (
          <Select
            mode="multiple"
            value={selectedDataTypes}
            onChange={(vals) => setSelectedDataTypes(vals)}
            style={{ width: "100%", marginTop: 8 }}
            placeholder="Select data type(s)"
          >
            {derivedDataTypes.map((dt) => (
              <Option key={dt} value={dt}>
                {dt}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <div style={{ textAlign: "right", marginTop: 16 }}>
        <Button style={{ marginRight: 8 }} onClick={resetFormState}>
          Reset
        </Button>
        <Button style={{ marginRight: 8 }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          icon={<FaDownload />}
          onClick={handleExportClick}
          loading={isExporting}
        >
          Export
        </Button>
      </div>
    </>
  );

  const renderSuccessStep = () => (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: 16, display: "flex", justifyContent: "center" }}>
        <FaCheckCircle style={{ color: "green", fontSize: 48 }} />
      </div>
      <h2 style={{ marginBottom: 16 }}>Export Successful!</h2>
      <p>Your CSV is ready. Click “Download” to save it.</p>
      <p style={{ fontStyle: "italic" }}>{exportFilename}</p>
      <div style={{ marginTop: 24 }}>
        <Button type="primary" onClick={handleDownloadClick} icon={<FaDownload />}>
          Download
        </Button>
        <Button style={{ marginLeft: 12 }} onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      title={step === "form" ? "Export Data" : "Export Complete"}
      destroyOnClose
    >
      <Spin spinning={isExporting && step === "form"}>
        <Form layout="vertical" form={form}>
          {step === "form" ? renderFormStep() : renderSuccessStep()}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ExportEntityModal;
