export const findDependentSections = (schema) => {
  const dependencies = {};

  Object.entries(schema.properties).forEach(([sectionKey, sectionSchema]) => {
    if (
      sectionSchema.type === "array" &&
      sectionSchema.items?.dependencies?.parent
    ) {
      const { parentSection } = sectionSchema.items.dependencies.parent;
      dependencies[sectionKey] = parentSection;
    }
  });

  return dependencies;
};

export const getDependencyChain = (schema) => {
  const dependencies = findDependentSections(schema);
  const chain = [];

  Object.entries(dependencies).forEach(([section, parentSection]) => {
    chain.push({
      section,
      parentSection,
      required: schema.properties[section]?.minItems > 0,
    });
  });

  return chain;
};

export const evaluateTemplate = (template, data) => {
  return template.replace(/\${([^}]+)}/g, (_, key) => data[key] || "");
};

export const transformExternalValidationResults = (validationResults) => {
  const externalErrors = {};

  validationResults.forEach((error) => {
    const path = error.path;
    const message = error.message;

    // Split the path into parts
    const pathParts = path.split(".");
    const firstPart = pathParts[0];

    let sectionKey;
    let fieldKey;
    let index = null;
    let sectionIdentifier;

    // Check if the first part indicates an array
    const arrayMatch = firstPart.match(/^([^\[]+)\[(\d+)\]$/);
    if (arrayMatch) {
      sectionKey = arrayMatch[1];
      index = parseInt(arrayMatch[2], 10);
      sectionIdentifier = `${sectionKey}-${index}`;
    } else {
      sectionKey = firstPart;
      sectionIdentifier = sectionKey;
    }

    fieldKey = pathParts.slice(1).join(".");

    if (!externalErrors[sectionIdentifier]) {
      externalErrors[sectionIdentifier] = {};
    }

    // If there's already an error for this field
    if (externalErrors[sectionIdentifier][fieldKey]) {
      // If it's already an array, add to it if the message is not already present
      if (Array.isArray(externalErrors[sectionIdentifier][fieldKey].messages)) {
        if (!externalErrors[sectionIdentifier][fieldKey].messages.includes(message)) {
          externalErrors[sectionIdentifier][fieldKey].messages.push(message);
        }
      } else {
        // Convert to array format
        const existingMessage = externalErrors[sectionIdentifier][fieldKey].message;
        externalErrors[sectionIdentifier][fieldKey] = {
          messages: existingMessage === message ? [message] : [existingMessage, message],
          severity: error.severity === "error" ? "error" : externalErrors[sectionIdentifier][fieldKey].severity,
        };
      }
    } else {
      // Create new error entry
      externalErrors[sectionIdentifier][fieldKey] = {
        message: message,
        messages: [message],
        severity: error.severity,
      };
    }
  });

  return externalErrors;
};

export const getReadOnlyValue = (fieldConfig, backendData) => {
  if (!fieldConfig || !backendData) return null;

  // Navigate through the backend data structure
  const attributes = backendData?.data?.attributes || {};
  return attributes[fieldConfig.source] || null;
};
