import React, { useEffect, useState } from "react";
import {
  getProduct,
  updateProductData,
  createProductData,
  deleteProductData,
  getProductData,
} from "../../services/productService";
import { getFormTemplates } from "../../services/formTemplateService";
import { useParams, useNavigate } from "react-router-dom";
//import SchemaBasedDataGrid from "../../components/ui/datagrid/SchemaBasedDataGrid";
import FormBuilder from "../../components/ui/datagrid/FormBuilder";
import { FaSpinner } from "react-icons/fa";
import ProductDetails from "../../components/ProductDetails";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { message, Button, Alert, Modal } from "antd";

const ProductDetailSupplier = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [productDataDetails, setProductDataDetails] = useState([]);
  const [availableFormTemplates, setAvailableFormTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingProductData, setEditingProductData] = useState(null);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [formModal, setFormModal] = useState({
    isOpen: false,
    form_data: null,
    mode: "add", // 'add' or 'edit'
    formDataDetail: null, // The product_data_detail being edited
    selectedFormTemplate: null, // Selected form_template for adding
  });

  useEffect(() => {
    fetchProductDetails();
    fetchProductDataDetails();
    fetchFormTemplates();
  }, [id]);

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      const response = await getProduct(id);
      const fetchedProduct = response.data.data;
      setProduct(fetchedProduct);
    } catch (error) {
      console.error("Error fetching product details:", error);
      message.error("Failed to fetch product details.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch available form templates
  const fetchFormTemplates = async () => {
    try {
      const response = await getFormTemplates();
      setAvailableFormTemplates(response.data.data);
    } catch (error) {
      console.error("Error fetching form templates:", error);
      message.error("Failed to fetch form templates.");
    }
  };

  // Fetch product data details
  const fetchProductDataDetails = async () => {
    try {
      setLoading(true);
      const response = await getProductData(id);
      setProductDataDetails(response.data.data);
      console.log("Product Data Details:", response.data.data);
    } catch (error) {
      console.error("Error fetching product data details:", error);
      message.error("Failed to fetch product data details.");
    } finally {
      setLoading(false);
    }
  };

  //TODO: No need for edit mode for supplier
  const openFormModal = (mode, formDataDetail = null) => {
    setEditingProductData(formDataDetail);
    //console.log("Form Data Detail:", formDataDetail);
    if (mode === "add") {
      setFormModal({
        isOpen: true,
        mode,
        id: formDataDetail?.id,
        form_data: null,
        formDataDetail: null,
        selectedFormTemplate: null,
      });
    } else if (mode === "edit") {
      setFormModal({
        isOpen: true,
        mode,
        form_data: formDataDetail.form_data,
        id: formDataDetail.id,
        formDataDetail: formDataDetail.form_template,
        selectedFormTemplate: formDataDetail.attributes?.form_template || null,
        status: formDataDetail.status.toLowerCase(),
        comment: formDataDetail.comment,
      });
    }
  };

  const closeFormModal = () => {
    setFormModal({
      isOpen: false,
      mode: "add",
      id: null,
      formDataDetail: null,
      selectedFormTemplate: null,
    });
  };

  const handleFormChange = (modeIsSet = false) => {
    const { status } = formModal;
    if (status === "accepted" || status === "submitted" || status === "forced_submitted") {
      if (modeIsSet) {
        return "preview";
      } else {
        setIsFormDirty(false);
      }
    } else {
      if (modeIsSet) {
        return "null";
      } else {
        setIsFormDirty(true);
      }
    }
  };

  const handleFormSubmit = async (data, forced_submit = false) => {
    if (formModal.mode === "edit") {
      // Update existing product_data_detail
      try {
        const actionType = forced_submit ? "force_submit" : "submit";
        await updateProductData(id, formModal.id, data, actionType);
        message.success("Product data updated successfully.");
        fetchProductDetails();
        fetchProductDataDetails();
        closeFormModal();
      } catch (error) {
        console.error("Error updating product data:", error);
        message.error("Failed to update product data.");
      }
    } else if (formModal.mode === "add") {
      // Create new product_data_detail
      try {
        await createProductData(id, data);
        message.success("Product data added successfully.");
        fetchProductDetails();
        closeFormModal();
      } catch (error) {
        console.error("Error adding product data:", error);
        message.error("Failed to add product data.");
      }
    }
    // Update product data details
  };

  const handleDraft = async (data) => {
    try {
      await updateProductData(id, formModal.id, data);
      message.success("Product data saved as draft.");
      fetchProductDetails();
      fetchProductDataDetails();
      closeFormModal();
    } catch (error) {
      console.error("Error saving product data as draft:", error);
      message.error("Failed to save product data as draft.");
    }
  };

  if (loading || !product) {
    return (
      <div className="p-6 flex flex-col items-center justify-center">
        <FaSpinner className="animate-spin h-8 w-8 text-blue-500" />
        <p className="mt-2 text-gray-600">Loading product details...</p>
      </div>
    );
  }

  // Prepare product data for display
  const productData = {
    "Product Name": product.attributes.name,
    Brand: product.attributes.brand_name,
    Supplier: product.attributes.supplier_name,
    Type: product.attributes.type || "N/A",
    comment: product.attributes.comment || "N/A",
    Status:
      product.attributes.status.charAt(0).toUpperCase() +
      product.attributes.status.slice(1),
    "Reference Brand": product.attributes.reference_brand,
    "Reference Supplier": product.attributes.reference_supplier,
    "Created At": new Date(product.attributes.created_at).toLocaleString(),
    "Updated At": new Date(product.attributes.updated_at).toLocaleString(),
  };

  // Transform productDataDetails for DataGrid
  const transformedData = productDataDetails.map((detail) => ({
    id: detail.id,
    //data_type: detail.attributes.data_type,
    "form_template.name": detail.attributes.form_template.name,
    status:
      detail.attributes.status.charAt(0).toUpperCase() +
      detail.attributes.status.slice(1),
    updated_at: new Date(detail.attributes.updated_at).toLocaleDateString(),
    form_data: detail.attributes.form_data,
    form_template: detail.attributes.form_template,
    form_data: detail.attributes.form_data,
    comment: detail.attributes.comment,
  }));

  const columns = [
    {
      title: "Form",
      dataIndex: "form_template.name",
      key: "form_template.name",
      sorter: (a, b) =>
        a.form_template.name.localeCompare(b.form_template.name),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => <StatusBadge status={text} />,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
    },
    {
      dataIndex: "actions",
      title: "Actions",
      key: "actions",

      
      render: (text, record) => {
        const status  = record.status.toLowerCase();
        let actionLabel = "";
        let isDisabled = false;

        switch (status) {
          case "to_complete":
            actionLabel = "Fill Data";
            break;
          case "submitted":
            actionLabel = "View Details";
            break;
          case "refused":
            actionLabel = "Correct Data";
            break;
          case "accepted":
          case "forced_submitted":
            actionLabel = "View Details";
            break;
          case "in_progress":
            actionLabel = "Continue Editing";
            break;
          default:
            actionLabel = "View Details";
            isDisabled = true;
        }
        return (
          <button
            onClick={() => openFormModal("edit", record)}
            disabled={isDisabled}
            className={`px-3 py-1 rounded ${
              isDisabled
                ? "bg-gray-300"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
          >
            {actionLabel}
          </button>
        );
      },
    },
  ];

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <Button onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Product Details */}
        <div className="bg-white p-6 rounded shadow">
          <ProductDetails productData={productData} />
        </div>
        {/* Product Data Details Section */}

        {/* Product Specifications */}
        <div className="lg:col-span-2 bg-white p-6 rounded shadow">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Product Specifications</h3>
          </div>
          <DataGrid
            columns={columns}
            data={transformedData}
            onRowClick={(row) => openFormModal("edit", row)}
          />
        </div>
      </div>

      {/* Form Modal */}
      <Modal
        visible={formModal.isOpen}
        onCancel={() => {
          if (isFormDirty) {
            Modal.confirm({
              title: "Unsaved Changes",
              icon: <ExclamationCircleOutlined />,
              content:
                "It looks like you have unsaved changes. Are you sure you want to leave this page? Any unsaved progress will be lost!",
              okText: "Stay",
              cancelText: "Leave",
              onOk: () => {},
              onCancel: () => {
                closeFormModal();
                setIsFormDirty(false); // Optionally reset dirty flag
              },
            });
          } else {
            closeFormModal();
          }
        }}
        footer={null}
        width="80%"
        destroyOnClose={true}
        style={{ top: 20 }}
        bodyStyle={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <h3 className="text-xl font-bold mb-4">
          {formModal.mode === "edit"
            ? "Edit " + product.attributes.name
            : "Add New Product Data"}
        </h3>

        {(formModal.mode === "edit" || formModal.selectedFormTemplate) && (
          <FormBuilder
            schema={formModal.formDataDetail?.schema}
            uiSchema={formModal.formDataDetail?.ui_schema}
            initialData={
              formModal.mode === "edit" ? formModal.form_data || null : null
            }
            comments={formModal.status === "refused" ? formModal.comment : null}
            onDraft={handleDraft}
            onSubmit={handleFormSubmit}
            onChange={handleFormChange}
            topLevelInfo={{
              data: product,
            }}
            mode={handleFormChange(true)}
          />
        )}
      </Modal>
    </div>
  );
};

export default ProductDetailSupplier;
