import { useEffect } from "react";
import { Form, Row, Col, Select, Alert, Tooltip } from "antd";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { renderField } from "../../../utils/fieldRenderers";
import { getReadOnlyValue } from "../../../utils/schemaUtils";

const FormFields = ({
  sectionSchema,
  sectionKey,
  index = null,
  formData,
  validationErrors,
  onChange,
  relationships,
  getAvailableParents,
  uiSchema = {},
  mode = "edit",
  topLevelInfo = null,
}) => {
  // Initialize form data with readonly values if not already set
  useEffect(() => {
    const initializeReadOnlyValues = () => {
      readOnlyFields.forEach((readOnlyConfig) => {
        const { fieldKey } = readOnlyConfig;
        const currentValue =
          index !== null
            ? formData[sectionKey]?.[index]?.[fieldKey]
            : formData[sectionKey]?.[fieldKey];

        // Only set if current value is empty/undefined
        if (!currentValue) {
          const backendValue = getReadOnlyValue(readOnlyConfig, topLevelInfo);
          if (backendValue) {
            onChange(sectionKey, index, fieldKey, backendValue);
          }
        }
      });
    };

    initializeReadOnlyValues();
  }, []);

  useEffect(() => {
    const updateReadOnlyValues = () => {
      const readOnlyFields = getReadOnlyFields();

      readOnlyFields.forEach((readOnlyConfig) => {
        const { fieldKey } = readOnlyConfig;
        const formValue =
          index !== null
            ? formData[sectionKey]?.[index]?.[fieldKey]
            : formData[sectionKey]?.[fieldKey];

        const backendValue = getReadOnlyValue(readOnlyConfig, topLevelInfo);

        // Update if backend value exists and is different from form value
        if (backendValue !== null && backendValue !== formValue) {
          onChange(sectionKey, index, fieldKey, backendValue);
        }
      });
    };

    updateReadOnlyValues();
  }, [topLevelInfo, formData, sectionKey, index, onChange, uiSchema]);

  const renderFieldLabel = (fieldSchema) => {
    const getTooltipIcon = (iconType) => {
      switch (iconType) {
        case "info":
          return (
            <InfoCircleOutlined style={{ marginLeft: 8, color: "#1890ff" }} />
          );
        case "exclamation":
          return (
            <ExclamationCircleOutlined
              style={{ marginLeft: 8, color: "#faad14" }}
            />
          );
        case "question":
        default:
          return (
            <QuestionCircleOutlined
              style={{ marginLeft: 8, color: "#1890ff" }}
            />
          );
      }
    };

    const tooltip = fieldSchema.tooltip;
    const label = (
      <span>
        {fieldSchema.title || fieldSchema.fieldName}
        {tooltip && (
          <Tooltip
            title={typeof tooltip === "string" ? tooltip : tooltip.text}
            placement={tooltip.placement || "right"}
          >
            {getTooltipIcon(tooltip.icon)}
          </Tooltip>
        )}
      </span>
    );

    return label;
  };

  if (!sectionSchema?.properties) return null;

  const getReadOnlyFields = () => {
    return uiSchema["ui:sectionConfig"]?.readOnly || [];
  };

  const readOnlyFields = getReadOnlyFields();

  const uiOrder = uiSchema["ui:order"] || [];

  // Get all field keys from the schema
  const allFieldKeys = Object.keys(sectionSchema.properties);

  // Determine the ordered list of fields
  const orderedFields = [
    ...uiOrder,
    ...allFieldKeys.filter((field) => !uiOrder.includes(field)),
  ];

  // Helper function to check if a field is readonly and get its source
  const getReadOnlyConfig = (fieldKey) => {
    const fields = getReadOnlyFields();
    //console.log("Checking readOnly for:", fieldKey, fields);

    // Find the field config - handle both formats
    const config = fields.find(
      (field) =>
        typeof field === "string"
          ? field === fieldKey // Simple format
          : field.fieldKey === fieldKey // Complex format
    );

    //console.log("Found config:", config);

    // If it's a string, transform it to the expected format
    if (typeof config === "string") {
      //console.log("Converting string config to object:", config);
      return {
        fieldKey: config,
        source: null, // No backend source for simple format
      };
    }

    return config;
  };

  // Helper to get field value based on whether it's readonly or not
  const getFieldValue = (fieldKey, readOnlyConfig) => {
    const formValue =
      index !== null
        ? formData[sectionKey]?.[index]?.[fieldKey]
        : formData[sectionKey]?.[fieldKey];

    // If it's a readonly field, check for backend value
    if (readOnlyConfig) {
      const backendValue = getReadOnlyValue(readOnlyConfig, topLevelInfo);

      // If backend value exists and is different from form value,
      // update the form data and return backend value
      if (backendValue !== null && backendValue !== formValue) {
        // Update form data with backend value
        setTimeout(() => {
          onChange(sectionKey, index, fieldKey, backendValue);
        }, 0);
        return backendValue;
      }
    }

    // If formValue exists and is not empty/null, use it
    if (formValue !== undefined && formValue !== null && formValue !== "") {
      return formValue;
    }

    // If no formValue and field is readonly, use backend data
    if (readOnlyConfig) {
      const backendValue = getReadOnlyValue(readOnlyConfig, topLevelInfo);
      if (backendValue !== null) {
        // Update form data with backend value
        setTimeout(() => {
          onChange(sectionKey, index, fieldKey, backendValue);
        }, 0);
        return backendValue;
      }
    }

    // Return formValue (which might be empty) for editable fields
    return formValue;
  };

  const missingFields = uiOrder.filter(
    (fieldKey) => !allFieldKeys.includes(fieldKey)
  );

  const getFieldError = (fieldKey) => {
    const sectionErrors =
      index !== null
        ? validationErrors[`${sectionKey}-${index}`]
        : validationErrors[sectionKey];

    return sectionErrors?.[fieldKey];
  };

  const getValidateStatus = (severity) => {
    switch (severity) {
      case "error":
        return "error";
      case "warning":
        return "warning";
      case "info":
        return "success";
      default:
        return "";
    }
  };

  return (
    <Row gutter={16}>
      {missingFields.length > 0 && (
        <Col span={24}>
          <Alert
            message={
              <span>
                <strong>Configuration Warning:</strong> The following fields
                specified in <code>ui:order</code> are missing in the schema or
                the fields specified are not correct:
                <ul>
                  {missingFields.map((field) => (
                    <li key={field}>
                      <code>{field}</code>
                    </li>
                  ))}
                </ul>
              </span>
            }
            type="warning"
            showIcon
            banner
          />
        </Col>
      )}
      {/* Relationship field for array sections */}
      {sectionSchema.dependencies?.parent && index !== null && (
        <Col span={12}>
          <Form.Item
            label="Associated With"
            required
            validateStatus={getFieldError("_relationship") ? "error" : ""}
            help={getFieldError("_relationship")?.message}
          >
            <Select
              value={relationships[`${sectionKey}-${index}`]}
              onChange={(value) =>
                onChange(sectionKey, index, "parentId", value)
              }
              options={getAvailableParents(sectionKey)}
              style={{ width: "100%" }}
              disabled={mode === "preview"}
            />
          </Form.Item>
        </Col>
      )}

      {/* Regular fields */}
      {[...new Set([...uiOrder, ...allFieldKeys])].map((fieldKey) => {
        if (fieldKey === "parentId") return null;

        const fieldSchema = sectionSchema.properties[fieldKey];
        if (!fieldSchema) return null;

        const readOnlyConfig = getReadOnlyConfig(fieldKey);
        const isReadOnly =
          Boolean(getReadOnlyConfig(fieldKey)) || mode === "preview";
        const value = getFieldValue(fieldKey, readOnlyConfig);
        const errorObj = getFieldError(fieldKey);
        const isRequired = sectionSchema.required?.includes(fieldKey);

        return (
          <Col span={12} key={fieldKey}>
            <Form.Item
              label={renderFieldLabel(fieldSchema)}
              required={isRequired && !isReadOnly}
              validateStatus={
                errorObj ? getValidateStatus(errorObj.severity) : ""
              }
              help={
                errorObj && (
                  <Tooltip
                    title={
                      errorObj.messages?.length > 1 ? (
                        <div style={{ maxWidth: "400px" }}>
                          {errorObj.messages.map((msg, idx) => (
                            <div key={idx} style={{ marginBottom: "4px" }}>
                              • {msg}
                            </div>
                          ))}
                        </div>
                      ) : null
                    }
                    placement="bottomLeft"
                  >
                    <span
                      style={{
                        cursor:
                          errorObj.messages?.length > 1 ? "help" : "default",
                      }}
                    >
                      {errorObj.message}
                      {errorObj.messages?.length > 1 && " (See more...)"}
                    </span>
                  </Tooltip>
                )
              }
            >
              {renderField(
                fieldSchema,
                value,
                (newValue) => {
                  if (!isReadOnly) {
                    onChange(sectionKey, index, fieldKey, newValue);
                  }
                },
                {
                  disabled: isReadOnly,
                  readOnly: isReadOnly,
                }
              )}
            </Form.Item>
          </Col>
        );
      })}
    </Row>
  );
};

export default FormFields;
