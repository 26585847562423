// components/FormSection.js
import React from "react";
import { Card, Button, Form } from "antd";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const FormSection = ({
  title,
  titleTemplate,
  sectionData,
  index,
  isExpanded,
  onToggle,
  onDuplicate,
  onDelete,
  canDelete,
  hasError,
  children,
  showActions,
  validationStatus,
  relationships,
  sectionKey,
  getAvailableParents,
}) => {
  const evaluateTitle = () => {
    if (!titleTemplate || !sectionData) return title;
    
    return titleTemplate.replace(/\${([^}]+)}/g, (_, key) => {
      if (key.startsWith('parent.')) {
        const parentId = relationships?.[`${sectionKey}-${index}`];
        let parent_info = getAvailableParents(sectionKey).find(p => p.value === parentId);
        // remove any string before "-" from the label like "parent.1 - " and replace with "Associated with "
        parent_info.label = parent_info?.label?.replace(/.*- /, 'Associated with: ');
        return parent_info?.label || '';
      }
      if (key === 'index') return index + 1;
      return sectionData[key] || '';
    });
  };

  return (
    <Card
      style={{
        marginBottom: 16,
        borderColor: hasError ? "#ff4d4f" : undefined,
      }}
      title={
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            margin: "-12px -24px",
            padding: "12px 24px",
          }}
          onClick={onToggle}
        >
          {isExpanded ? (
            <CaretDownOutlined style={{ marginLeft: 12 }} />
          ) : (
            <CaretRightOutlined style={{ marginLeft: 12 }} />
          )}
          <span style={{ marginLeft: 8 }}>{evaluateTitle()}</span>
        </div>
      }
      extra={
        showActions && (
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              type="text"
              icon={<CopyOutlined />}
              onClick={onDuplicate}
              style={{ marginRight: 8 }}
            />
            {canDelete && (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={onDelete}
                danger
              />
            )}
          </div>
        )
      }
      bodyStyle={{
        padding: isExpanded ? "24px" : 0,
        display: isExpanded ? "block" : "none",
      }}
      headStyle={{
        padding: 0,
        margin: 0,
        backgroundColor:
          validationStatus === "error"
            ? "#fff2f0"
            : validationStatus === "warning"
            ? "#fffbe6"
            : undefined,
        borderBottom: isExpanded ? undefined : "none",
      }}
    >
      {isExpanded && <Form layout="vertical">{children}</Form>}
    </Card>
  );
};
export default FormSection;
