import { useState, useEffect } from "react";
import { message } from "antd";
import { getProducts } from "../services/productService";
import { getBrands } from "../services/brandService";
import { getSuppliers } from "../services/supplierService";
import { getFormTemplates } from "../services/formTemplateService";

export function useManageProducts() {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [formTemplates, setFormTemplates] = useState([]);
  const [productDataMap, setProductDataMap] = useState({});
  const [includedData, setIncludedData] = useState([]);
  const [dataTypes, setDataTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        fetchProducts(),
        fetchBrands(),
        fetchSuppliers(),
        fetchFormTemplates(),
      ]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch products
  const fetchProducts = async () => {
    try {
      const response = await getProducts();
      if (!response.data.data.length) {
        message.info("No products found, please add a new product.");
      }
      const productsData = response.data.data;
      const included = response.data.included || [];

      // Build productDataMap
      const productData = included.filter(
        (item) => item.type === "product_datum"
      );
      const map = {};
      productData.forEach((datum) => {
        const productId = datum.relationships.product.data.id;
        if (!map[productId]) map[productId] = [];
        map[productId].push(datum.attributes);
      });

      // Distinct data types
      const distinctDataTypes = [
        ...new Set(productData.map((d) => d.attributes.data_type)),
      ];

      setProducts(productsData);
      setProductDataMap(map);
      setIncludedData(included);
      setDataTypes(distinctDataTypes);
    } catch (err) {
      console.error("Error fetching products", err);
      message.error("Failed to fetch products.");
    }
  };

  // Fetch brands
  const fetchBrands = async () => {
    try {
      const res = await getBrands();
      setBrands(res.data.data);
    } catch (error) {
      console.error("Error fetching brands", error);
      message.error("Failed to fetch brands.");
    }
  };

  // Fetch suppliers
  const fetchSuppliers = async () => {
    try {
      const res = await getSuppliers();
      setSuppliers(res.data.data);
    } catch (error) {
      console.error("Error fetching suppliers", error);
      message.error("Failed to fetch suppliers.");
    }
  };

  // Fetch form templates
  const fetchFormTemplates = async () => {
    try {
      const res = await getFormTemplates();
      setFormTemplates(res.data.data);
    } catch (error) {
      console.error("Error fetching form templates", error);
      message.error("Failed to fetch form templates.");
    }
  };

  return {
    products,
    brands,
    suppliers,
    formTemplates,
    productDataMap,
    dataTypes,
    includedData,
    loading,
    fetchProducts, // in case need of manual refresh
  };
}
