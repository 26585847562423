import React, { useEffect, useState, useContext } from "react";
import { getSupplierProducts } from "../../services/supplierService";
//import { getProductData } from "../../services/productService";
import ProductDataList from "./ProductDataList";
import KPI from "../../components/ui/kpis/KPI";
import OnboardingSection from "./OnboardingSection";
import {
  FaBox,
  FaCheckCircle,
  FaHourglassHalf,
  FaChartLine,
  FaSpinner,
  FaInfoCircle,
  FaExclamationTriangle,
  FaPaperPlane,
} from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../contexts/AuthContext";
import { Alert, Button, message } from "antd"; // Import Ant Design components

const SupplierDashboard = () => {
  const { user } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [productDataMap, setProductDataMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);

  // Data for KPI Cards
  const [totalProducts, setTotalProducts] = useState(0);
  const [pendingProducts, setPendingProducts] = useState(0);
  const [inProgressProducts, setInProgressProducts] = useState(0);
  const [refusedProducts, setRefusedProducts] = useState(0);
  const [submittedProducts, setSubmittedProducts] = useState(0);
  const [acceptedProducts, setAcceptedProducts] = useState(0);

  const actionableStatuses = ["to_complete", "in_progress", "refused"];

  const hideOnboarding = () => {
    setShowOnboarding(false);
    localStorage.setItem("onboardingHidden", "true");
  };

  useEffect(() => {
    const onboardingHidden = localStorage.getItem("onboardingHidden");
    if (onboardingHidden === "true") {
      setShowOnboarding(false);
    }
  }, []);

  useEffect(() => {
    //console.log("User data changed:", user);
    if (user && user.id) {
      fetchSupplierProducts(user.supplier_id);
    } else {
      setLoading(false);
      setError(true);
      message.error("Invalid user data. Please log in again.");
    }
  }, [user]);

  const fetchSupplierProducts = async (supplierId) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSupplierProducts(supplierId);
      if (response.data.data.length === 0) {
        message.info("No product(s) found for you");
        setLoading(false);
        return;
      }
      const fetchedProducts = response.data.data;

      /*const actionableProducts = products.filter((product) => {
        const productDataArray = productDataMap[product.id];
        return productDataArray.some((productData) =>
          actionableStatuses.includes(productData.attributes.status)
        );
      });*/
      //setProducts(fetchedProducts);

      // Fetch product data for all products
      /*const productDataPromises = fetchedProducts.map((product) =>
        getProductData(product.id)
      );

      const productDataResponses = await Promise.all(productDataPromises);*/

      // Map product IDs to their product data
      const dataMap = {};
      const allProducts = {};
      if (response.data.included) {
        fetchedProducts.forEach((product) => {
          const productData = response.data.included.filter(
            (item) =>
              item.type === "product_datum" &&
              product.relationships.product_data.data.some(
                (pd) => pd.id === item.id
              )
          );
          // Filter product data by actionable statuses
          allProducts[product.id] = productData;
          dataMap[product.id] = productData.filter((pd) =>
            actionableStatuses.includes(pd.attributes.status)
          );
        });
      }
      // Filter out products with no actionable data and return them all
      const actionableProducts = fetchedProducts.filter(
        (product) => dataMap[product.id]?.length > 0
      );

      setProducts(actionableProducts);
      setProductDataMap(dataMap);

      processKPIData(allProducts);

      setLoading(false);
    } catch (err) {
      console.error("Error fetching supplier products or product data:", err);
      setError(true);
      message.error("Failed to fetch supplier products or product data.");
      setLoading(false);
    }
  };

  // Process data for KPI Cards
  const STATUS_COUNTS = {
    total: 0,
    submitted: 0,
    accepted: 0,
    inProgress: 0,
    toFill: 0,
    refused: 0,
  };

  const processKPIData = (productDataMap) => {
    const counts = { ...STATUS_COUNTS };

    Object.values(productDataMap).forEach((productDataArray) => {
      productDataArray.forEach((productData) => {
        counts.total += 1;
        const status = productData.attributes.status;

        switch (status) {
          case "submitted":
          case "forced_submitted":
            counts.submitted += 1;
            break;
          case "accepted":
            counts.accepted += 1;
            break;
          case "in_progress":
            counts.inProgress += 1;
            break;
          case "to_complete":
            counts.toFill += 1;
            break;
          case "refused":
            counts.refused += 1;
            break;
          default:
            break;
        }
      });
    });

    setTotalProducts(counts.total);
    setSubmittedProducts(counts.submitted);
    setAcceptedProducts(counts.accepted);
    setPendingProducts(counts.inProgress);
    setInProgressProducts(counts.toFill);
    setRefusedProducts(counts.refused);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin h-10 w-10 text-blue-500" />
        <span className="ml-2 text-gray-700">Loading Dashboard...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 text-center">
        <Alert
          message="Error"
          description="Unable to load the dashboard. Please try again later."
          type="error"
          showIcon
          action={
            <Button
              size="small"
              type="primary"
              onClick={() => fetchSupplierProducts(user.supplier_id)}
            >
              Retry
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Button to show the onboarding section when it's hidden */}
      <div className="flex justify-end mb-4">
        {!showOnboarding && (
          <button
            onClick={() => {
              setShowOnboarding(true);
              localStorage.removeItem("onboardingHidden");
            }}
            className="bg-gray-800 text-white px-4 py-2 rounded-md"
          >
            <FaInfoCircle className="h-4 w-4 inline-block mr-2" />
            Show onboarding details
          </button>
        )}
      </div>

      {/* Onboarding Section */}
      {showOnboarding && <OnboardingSection onHide={hideOnboarding} />}

      <h1 className="text-3xl font-bold mb-6 text-gray-800">Your Dashboard</h1>

      {/* KPI Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <KPI
          title="Total requests"
          value={totalProducts}
          icon={<FaBox className="h-6 w-6 text-indigo-500" />}
          color="border-indigo-500"
        />
        <KPI
          title="Requests to complete"
          value={inProgressProducts}
          icon={<FaHourglassHalf className="h-6 w-6 text-yellow-500" />}
          color="border-yellow-500"
        />
        <KPI
          title="Requests in progress "
          value={pendingProducts}
          icon={<FaChartLine className="h-6 w-6 text-blue-500" />}
          color="border-blue-200"
        />
        <KPI
          title="Submitted requests"
          value={submittedProducts}
          icon={<FaPaperPlane className="h-6 w-6 text-purple-500" />}
          color="border-purple-500"
        />
        <KPI
          title="Refused requests"
          value={refusedProducts}
          icon={<FaExclamationTriangle className="h-6 w-6 text-red-500" />}
          color="border-red-500"
        />
        <KPI
          title="Accepted requests"
          value={acceptedProducts}
          icon={<FaCheckCircle className="h-6 w-6 text-green-500" />}
          color="border-green-500"
        />
      </div>

      {/* Product List Section */}
      <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800">
          Product Data Requests
        </h2>
        <ProductDataList
          products={products}
          productDataMap={productDataMap}
          refreshProducts={() => fetchSupplierProducts(user.id)}
        />
      </div>
    </div>
  );
};

export default SupplierDashboard;
