// utils/fieldRenderers.js
import React from "react";
import { Input, InputNumber, Select, Checkbox } from "antd";

export const renderField = (fieldSchema, value, onChange, options = {}) => {
  if (!fieldSchema) return null;
  const { disabled, readOnly } = options;

  const isGroupHeader = (value) => {
    return (
      typeof value === "string" &&
      value.startsWith("=====") &&
      value.endsWith("=====")
    );
  };

  const commonProps = {
    disabled: disabled || readOnly,
    style: {
      width: "100%",
      backgroundColor: readOnly ? "#f5f5f5" : undefined,
      cursor: readOnly ? "not-allowed" : undefined,
    },
  };

  switch (fieldSchema.type.toLowerCase()) {
    case "string":
      if (fieldSchema.enum) {
        const processedOptions = fieldSchema.enum.map((opt) => ({
          value: opt,
          label: opt,
          disabled: isGroupHeader(opt),
        }));

        return (
          <Select
            {...commonProps}
            showSearch
            value={value}
            onChange={onChange}
            options={processedOptions}
            allowClear
          />
        );
      }

      return (
        <Input
          {...commonProps}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case "number":
      return <InputNumber {...commonProps} value={value} onChange={onChange} />;

    case "float":
      return (
        <InputNumber
          {...commonProps}
          value={value}
          onChange={onChange}
          // Convert dot -> comma for display
          formatter={(val) => (val ? val.toString().replace(".", ",") : "")}
          // Convert comma -> dot internally
          parser={(val) => (val ? val.replace(",", ".") : "")}
          step={0.01}
        />
      );

    case "boolean":
      return (
        <Checkbox
          {...commonProps}
          checked={value === "yes"}
          onChange={(e) => onChange(e.target.checked ? "yes" : "no")}
        />
      );

    case "array":
      if (fieldSchema.items?.enum) {
        return (
          <Select
            {...commonProps}
            showSearch
            mode="multiple"
            value={value}
            onChange={onChange}
            options={fieldSchema.items.enum.map((opt) => ({
              value: opt,
              label: opt,
            }))}
          />
        );
      }
      return null;

    default:
      return null;
  }
};
