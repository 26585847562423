import api from './api';

export const acceptInvitation = (token, password, passwordConfirmation) => {
  return api.post('/suppliers/accept_invitation', {
    invitation: {
      token,
      password,
      password_confirmation: passwordConfirmation,
    },
  });
};

export const createSupplier = (supplierData) => {
  return api.post('/suppliers', { supplier: supplierData });
};

export const getSuppliers = () => {
  return api.get('/suppliers');
};

export const getSupplier = (supplierId) => {
  return api.get(`/suppliers/${supplierId}`);
};

export const updateSupplier = (supplierId, supplierData) => {
  return api.put(`/suppliers/${supplierId}`, { supplier: supplierData });
};

export const deleteSupplier = (supplierId) => {
  return api.delete(`/suppliers/${supplierId}`);
};


export const getSupplierBrands = () => {
  return api.get('/suppliers/brands');
};

export const getSupplierProducts = (supplierId) => {
  return api.get(`/suppliers/${supplierId}/products`);
};

export const importSuppliers = async (file, onProgress) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await api.post('/suppliers/import', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const { import_task_id } = response.data;

    if (!import_task_id) {
      throw new Error("Failed to initiate product import.");
    }

    // Poll for status every 2 seconds
    const statusCheckInterval = setInterval(async () => {
      try {
        const statusResponse = await api.get(
          `/suppliers/${import_task_id}/status`
        );
        const status = statusResponse.data;

        onProgress({
          total: status.total_rows,
          processed: status.status === "completed" ? status.total_rows : status.processed_rows,
          success: status.status === "completed" ? status.total_rows : status.success_count,
          errors: status.error_count,
          status: status.status,
          recentErrors: status.recent_errors,
        });

        if (status.status === "completed" || status.status === "failed") {
          clearInterval(statusCheckInterval);
        }
      } catch (error) {
        clearInterval(statusCheckInterval);
        onProgress({
          error: "Failed to fetch import status: " + error.message,
        });
      }
    }, 2000);

    return import_task_id;
  } catch (error) {
    throw error;
  }
}
