import React, { useState, useEffect, useMemo, useContext } from "react";
import { getSupplierProducts } from "../../services/supplierService";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import KPIDashboard from "../../components/ui/kpis/KPIDashboard";
import { useNavigate } from "react-router-dom";
import { message, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import {
  FaBoxOpen,
  FaChartPie,
  FaChartBar,
  FaArchive,
  FaExclamationTriangle,
} from "react-icons/fa";

import { ClipLoader } from "react-spinners";
import { AuthContext } from "../../contexts/AuthContext";

import StatusBadge from "../../components/ui/badges/StatusBadge";

// Define status priority: Refused > To Complete > In Progress > Submitted > Archived
const STATUS_PRIORITY = [
  "refused",
  "to_complete",
  "in_progress",
  "forced_submitted",
  "submitted",
  "accepted",
];

const SupplierProductList = () => {
  const { user } = useContext(AuthContext);

  // State variables
  // State variables
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [productDataMap, setProductDataMap] = useState({});

  // Search state
  const navigate = useNavigate();

  // Fetch products, brands, and suppliers on component mount
  useEffect(() => {
    fetchSupplierProducts(user.id);
  }, []);

  // TODO: Fetch all products, same as ManageProducts.js (to be refactored or reused)
  /* Fetch products on component mount
  useEffect(() => {
    fetchSupplierProducts(user.id);
  }, [user.id]);*/

  const fetchSupplierProducts = async (supplierId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getSupplierProducts(supplierId);
      if (response.data.data.length === 0) {
        message.info("No product(s) found for you");
        setLoading(false);
        return;
      }
      const fetchedProducts = response.data.data;
      const included = response.data.included || [];

      // Map product IDs to their associated productdatum
      const dataMap = {};
      fetchedProducts.forEach((product) => {
        const associatedData = included.filter(
          (item) =>
            item.type === "product_datum" &&
            product.relationships.product_data.data.some(
              (pd) => pd.id === item.id
            )
        );
        dataMap[product.id] = associatedData;
      });

      setProducts(fetchedProducts);
      setProductDataMap(dataMap);
    } catch (err) {
      console.error("Error fetching supplier products:", err);
      setError("Failed to fetch supplier products.");
      message.error("Failed to fetch supplier products.");
    } finally {
      setLoading(false);
    }
  };

  // Handle view details action
  const handleViewDetails = (productId) => {
    // Get path from current URL and navigate to product detail page
    const currentPath = window.location.pathname;
    navigate(`${currentPath}/${productId}`);
  };

  /**
   * Determines the least advanced status from an array of statuses.
   * @param {Array<string>} statuses - Array of status strings.
   * @returns {string} - The least advanced status.
   */
  const getLeastAdvancedStatus = (statuses) => {
    if (!statuses || statuses.length === 0) return "N/A";

    // Normalize statuses by mapping 'forced_submitted' to 'submitted'
    const normalizedStatuses = statuses.map((status) =>
      status.toLowerCase() === "forced_submitted"
        ? "submitted"
        : status.toLowerCase()
    );

    for (let status of STATUS_PRIORITY) {
      if (normalizedStatuses.includes(status)) {
        return status;
      }
    }
    return "N/A";
  };

  // Define DataGrid columns
  const productColumns = [
    {
      title: "Product Name",
      dataIndex: ["attributes", "name"],
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Référence",
      dataIndex: ["attributes", "reference_supplier"],
      key: "reference_supplier",
      sorter: (a, b) =>
        a.attributes.reference_supplier.localeCompare(
          b.attributes.reference_supplier
        ),
    },
    {
      title: "Brand",
      dataIndex: ["attributes", "brand_name"],
      key: "brand_name",
      sorter: (a, b) => a.brand_name.localeCompare(b.brand_name),
    },
    {
      title: "Product Data Status",
      key: "product_data_status",
      dataIndex: "product_data_status",
      defaultSortOrder: "ascend", // Sorts based on the defined priority
      filters: [
        { text: "To complete", value: "to_complete" },
        { text: "In Progress", value: "in_progress" },
        { text: "Refused", value: "refused" },
        { text: "Submitted", value: "submitted" },
       // { text: "Forced submitted", value: "forced_submitted" },
        { text: "Accepted", value: "accepted" },
      ],
      onFilter: (value, record) =>
        record.product_data_status.toLowerCase() === value,
      sorter: (a, b) => {
        const statusA = STATUS_PRIORITY.indexOf(
          a.product_data_status.toLowerCase()
        );
        const statusB = STATUS_PRIORITY.indexOf(
          b.product_data_status.toLowerCase()
        );
        return statusA - statusB;
      },
      render: (status) => (
        <>
          <StatusBadge status={status} key={status} />
        </>
      ),
    },
    {
      title: "Published At",
      dataIndex: "published_at",
      key: "published_at",
      sorter: (a, b) => new Date(a.published_at) - new Date(b.published_at),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => handleViewDetails(record.id)}
          aria-label={`View details of ${record.attributes.name}`}
        >
          View
        </Button>
      ),
    },
  ];

  // Calculate KPIs
  const totalProducts = products.length;
  const calculateKPIs = useMemo(() => {
    let totalInProgress = 0;
    let totalSubmitted = 0;
    let totalArchived = 0;
    let refusedProducts = 0;

    products.forEach((product) => {
      const productData = productDataMap[product.id] || [];

      productData.forEach((data) => {
        const status = data.attributes.status.toLowerCase();
        if (status === "in_progress") {
          totalInProgress += 1;
        }
        if (status === "submitted" || status === "forced_submitted") {
          totalSubmitted += 1;
        }
        if (status === "refused") {
          refusedProducts += 1;
        }
      });

      if (product.attributes.status.toLowerCase() === "archived") {
        totalArchived += 1;
      }
    });

    return { totalInProgress, totalSubmitted, totalArchived, refusedProducts };
  }, [products, productDataMap]);

  // Define KPI configurations
  const productKPIs = [
    {
      title: "Total Products",
      value: totalProducts,
      icon: <FaBoxOpen className="h-6 w-6 text-indigo-400" />,
      color: "border-l-4 border-indigo-500",
    },
    {
      title: "In Progress Products",
      value: calculateKPIs.totalInProgress,
      icon: <FaChartBar className="h-6 w-6 text-blue-400" />,
      color: "border-l-4 border-blue-500",
    },
    {
      title: "Refused requests",
      value: calculateKPIs.refusedProducts,
      icon: <FaExclamationTriangle className="h-6 w-6 text-red-400" />,
      color: "border-l-4 border-red-500",
    },
    {
      title: "Submitted Products",
      value: calculateKPIs.totalSubmitted,
      icon: <FaChartPie className="h-6 w-6 text-purple-400" />,
      color: "border-l-4 border-purple-500",
    },
    {
      title: "Accepted requests",
      value: calculateKPIs.totalArchived,
      icon: <FaArchive className="h-6 w-6 text-green-400" />,
      color: "border-l-4 border-green-500",
    },
  ];

  // Prepare DataGrid data
  const dataSource = useMemo(() => {
    return products.map((product) => {
      const productData = productDataMap[product.id] || [];
      const statuses = productData.map((data) => data.attributes.status);
      const leastStatus = getLeastAdvancedStatus(statuses);
      console.log("productData", productData);
      return {
        key: product.id,
        id: product.id,
        name: product.attributes.name,
        attributes: product.attributes,
        reference_supplier: product.attributes.reference_supplier,
        brand_name: product.attributes.brand_name,
        product_data_status: leastStatus,
        published_at: product.attributes.created_at
          ? new Date(product.attributes.created_at).toLocaleString()
          : "N/A",
      };
    });
  }, [products, productDataMap]);

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">All Products</h2>
      </div>

      {/* KPI Dashboard */}
      <KPIDashboard kpis={productKPIs} />

      {/* DataGrid and Loading State */}
      {loading ? (
        <div className="flex justify-center items-center text-gray-500">
          <ClipLoader size={50} />
        </div>
      ) : (
        <>
          <DataGrid
            columns={productColumns}
            data={dataSource}
            onRowClick={null} // No row click handling as actions are within the row
          />
        </>
      )}
    </div>
  );
};

export default SupplierProductList;
